import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.starts-with.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "allianzAktHistory card"
  }, [_c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.isShowingistory = !_vm.isShowingistory;
      }
    }
  }, [_vm._v("Journal")])]), _vm.isShowingistory ? _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "btn _bordered",
    on: {
      "click": _vm.fetchHistory
    }
  }, [_vm._v("neu laden")]), !_vm.history || _vm.$_size(_vm.history) == 0 ? _c('div', {
    staticClass: "logs"
  }, [_c('p', [_vm._v("noch keine Einträge vorhanden")])]) : _vm._e(), _vm.history && _vm.$_size(_vm.history) > 0 ? _c('div', {
    staticClass: "logs"
  }, [_c('ul', _vm._l(_vm.historyReverserd, function (entry) {
    return !_vm.$_.isEmpty(entry.updates) ? _c('li', {
      staticClass: "mainentry"
    }, [_c('div', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(_vm.$dayjs(entry.modified).format(_vm.MAIN_DATE_FORMAT))), _c('div', {
      staticClass: "fr"
    }, [_c('small', [_vm._v(_vm._s((_vm._fbUsersObj[entry.modifiedBy] || {}).name || '-'))])])]), _vm.history ? _c('ul', {
      staticClass: "padd-top"
    }, _vm._l(entry.updates, function (val, key) {
      return _c('li', [key == 'status' ? _c('div', [_c('small', [_c('div', {
        staticClass: "bold fl"
      }, [_vm._v("Statusänderung")]), _c('div', {
        staticClass: "fr bold color-red"
      }, [_vm._v(_vm._s(_vm.stautsById(val)))])]), _c('div', {
        staticClass: "clear"
      })]) : _vm.excludeFromHistory.includes(key) ? _c('div') : ['generalInfo', 'notices', 'noticesSv2'].includes(key) ? _c('div', [_c('small', ['generalInfo' == key ? _c('div', {
        staticClass: "bold fl"
      }, [_vm._v("Office Notizen:")]) : _vm._e(), 'notices' == key ? _c('div', {
        staticClass: "bold fl"
      }, [_vm._v("SV Notizen:")]) : _vm._e(), 'noticesSv2' == key ? _c('div', {
        staticClass: "bold fl"
      }, [_vm._v("Notizen SV2:")]) : _vm._e(), _c('br'), _c('div', {
        staticClass: "no-p-margin color-grey",
        domProps: {
          "innerHTML": _vm._s(_vm.unformat(val))
        }
      })]), _c('div', {
        staticClass: "clear"
      })]) : ['svUid', 'svUid2'].includes(key) ? _c('div', [_c('small', [_c('div', {
        staticClass: "bold fl"
      }, [_vm._v("SV Zuteilung (" + _vm._s(key) + "):")]), _c('div', {
        staticClass: "fr color-blue bold"
      }, [_vm._v(_vm._s((_vm._fbUsersObj[val] || {}).name || val))])]), _c('div', {
        staticClass: "clear"
      })]) : Object.keys(_vm.flagTrans).includes(key) ? _c('div', [_c('small', [_c('div', {
        staticClass: "bold fl"
      }, [_vm._v(_vm._s(_vm.flagTrans[key]) + ":")]), _c('div', {
        staticClass: "fr color-orange bold"
      }, [_vm._v(_vm._s(_vm.makeBool(val)))])]), _c('div', {
        staticClass: "clear"
      })]) : ['reportData-html'].includes(key) ? _c('div', [_c('small', [_c('div', {
        staticClass: "bold fl"
      }, [_vm._v("Bericht:")]), _c('br'), _c('div', {
        staticClass: "fr"
      }, [_c('pre', {
        staticClass: "wp color-darkblue"
      }, [_vm._v(_vm._s((_vm._fbUsersObj[val] || {}).name || val))])])]), _c('div', {
        staticClass: "clear"
      })]) : key.startsWith('__history') ? _c('div') : key == 'modified' ? _c('div') : _c('div', [_c('small', [_c('div', {
        staticClass: "bold fl"
      }, [_vm._v(_vm._s(key) + ":")]), _c('div', {
        staticClass: "fr"
      }, [_vm._v(_vm._s(val))])]), _c('div', {
        staticClass: "clear"
      })])]);
    }), 0) : _vm._e()]) : _vm._e();
  }), 0)]) : _vm._e()]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };